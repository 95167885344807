export const data = [
	{
		info: `The origins of AskMed can be traced back to 2022 when Dr. Mondel George, a highly accomplished Grenadian physician with a diverse educational background, embarked on a mission to revolutionize global healthcare accessibility. With his profound understanding of medicine, coupled with his Master of Business Administration, Master of Education, and Bachelor of Life Science, Dr. George set out to create a mobile application that would redefine the way people access medical expertise.

Driven by a deep-rooted passion for improving healthcare worldwide, Dr. George envisioned AskMed as a transformative solution that would empower individuals by providing convenient and reliable access to medical professionals. Recognizing the power of technology to bridge gaps and revolutionize industries, he harnessed his expertise to pioneer the development of the AskMed mobile application.

Understanding the complexity and significance of the task at hand, Dr. George forged partnerships with a team of seasoned IT experts, distinguished physicians, experienced nurses, and various other healthcare professionals. This collaborative effort aimed to ensure the highest standards of quality and expertise in delivering healthcare services through the AskMed Mobile Application Platform.

Together, this exceptional team dedicated their collective skills, knowledge, and resources to build a platform that would transcend geographical boundaries and provide individuals with instant access to reliable medical guidance. By harnessing cutting-edge technology, AskMed aimed to break down barriers and connect patients with a global network of qualified healthcare professionals, thus facilitating improved healthcare outcomes one nation at a time.

Through the collaborative efforts of the team, AskMed emerged as a comprehensive mobile application platform, poised to disrupt the healthcare industry. It seamlessly integrated advanced features such as 24/7 access to medical experts, secure storage of medical data, medication reminders, and real-time updates on health activities. This holistic approach aimed to empower individuals to take control of their health and make informed decisions with the support of trusted medical professionals.

The journey of AskMed is marked by a relentless pursuit of excellence and a commitment to ensuring the highest standards of security, privacy, and user experience. Driven by their shared vision, the team behind AskMed remains steadfast in their dedication to improving global healthcare accessibility and transforming lives through their innovative mobile application.

As AskMed continues to evolve and touch the lives of individuals around the world, its rich history serves as a testament to the profound impact that visionary minds and collaborative efforts can have on shaping the future of healthcare.`,
	},
];

export const data1 = [
	{
		info: `Our goal is to bridge the gap between patients and healthcare professionals, offering a holistic approach to healthcare management that is convenient, personalized, and reliable. By leveraging the power of mobile technology, we aim to enhance the quality of care, streamline communication, and ultimately improve health outcomes for individuals worldwide.

We envision a future where medical information is securely and conveniently stored in one place, ensuring that patients and their healthcare providers have instant access to accurate and up-to-date data. Our application will empower users to make informed decisions about their health, enabling proactive management of chronic conditions, timely medication adherence, and comprehensive tracking of health activities.

Through our 24-hour doctor access feature, users can consult qualified medical professionals from the comfort of their homes, providing reassurance, guidance, and prompt responses to health concerns. This on-demand medical support ensures that expert advice is just a few taps away, improving accessibility and reducing unnecessary emergency room visits.

The application is most suitable for countries with complex and limited healthcare resources, companies, insurance provider and large organization who acknowledges the importance of their clients, staff or members having access to a medic or physician. Having our AskMed Mobile Application is literally having a medical assistant in your pocket with 24 hours access.`,
	},
];
