import React from "react";
import "./contact.css";
const Product = () => {
	return (
		<div className="" id="faqs">
			<h1 className=" text-center text-[35px] text-[#1F6CDD] font-bold mt-32">
				Frequently Asked Question
			</h1>
			<ul className="accordion md:w-[50%] w-[80%] mx-auto flex flex-col gap-7">
				<li>
					<input type="radio" name="accordion" id="second" />
					<label htmlFor="second">How does the subscription works?</label>
					<div className="content">
						<p>
							Clients can utilize most of our application functions for
							absolutely free. Using our direct access to a medic, would require
							a subscription which will give users unlimited access to the
							period subscribed. Users can simply extend their subscription once
							the previous subscription has expired.
						</p>
					</div>
				</li>

				<li>
					<input type="radio" name="accordion" id="fourth" />
					<label htmlFor="fourth">Are there any hidden fees</label>
					<div className="content">
						<p>
							Askmed does not have any hidden fees. We have the capability to
							source additional help for clients that would be at no additional
							cost to clients. We partner with pharmacies, laboratories and
							other healthcare organizations to provide the best prices,
							discounts and advantages for our users.
						</p>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default Product;
