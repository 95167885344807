import React, { useState } from "react";
import { Link } from "react-scroll";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../assets/logo.png";
const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="w-full bg-white fixed shadow-md z-[20]">
      <div className=" flex justify-between items-center w-[80%] mx-auto h-24 text-black">
        <h1 className="text-[34px] font-bold  text-[#FC3030] flex">
          <span className="pt-[5px]">
            <img src={logo} alt="" className="w-[50px]" />
          </span>
          Ask<span className="text-[#1F6CDD]">med</span>
        </h1>{" "}
        <ul className="hidden md:flex ">
          <li className="p-4 cursor-pointer hover:text-gray-500">
            <Link to="home" spy={true} smooth={true} offset={50} duration={500}>
              Home
            </Link>
          </li>
          <li className="p-4 cursor-pointer hover:text-gray-500">
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              About
            </Link>
          </li>
          <li className="p-4 cursor-pointer hover:text-gray-500">
            <Link to="team" spy={true} smooth={true} offset={50} duration={500}>
              Team
            </Link>
          </li>
          <li className="p-4 cursor-pointer hover:text-gray-500">
            <Link to="faqs" spy={true} smooth={true} offset={50} duration={500}>
              FAQs
            </Link>
          </li>
        </ul>
        <Link
          className="hidden md:block md:text-white md:bg-[#1d5cd0] md:pt-[10px] md:pb-[10px] md:pl-[30px] md:pr-[30px] md:mr-12 md:rounded-[12px]"
          to="contact"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
        >
          Contact
        </Link>
        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <ul
          className={
            nav
              ? "fixed left-0 top-0 w-[80%] h-full pl-9 pt-7  bg-[#fff] ease-in-out duration-500"
              : "ease-in-out duration-500 fixed top-[-100%]"
          }
        >
          <h1 className="w-full text-3xl font-bold text-[#41c9f7] m-4">
            <span className="pt-[5px]">
              <img src={logo} alt="" />
            </span>
            Ask<span className="text-[#1F6CDD]">med</span>
          </h1>{" "}
          <li className="p-4 cursor-pointer hover:text-gray-500">
            <Link
              onClick={() => {
                setNav(false);
              }}
              to="home"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Home
            </Link>
          </li>
          <li className="p-4 cursor-pointer hover:text-gray-500">
            <Link
              onClick={() => {
                setNav(false);
              }}
              to="about"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              About
            </Link>
          </li>
          <li className="p-4 cursor-pointer hover:text-gray-500">
            <Link
              onClick={() => {
                setNav(false);
              }}
              to="team"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Team
            </Link>
          </li>
          <li className="p-4 cursor-pointer hover:text-gray-500">
            <Link
              onClick={() => {
                setNav(false);
              }}
              to="faqs"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              FAQs
            </Link>
          </li>
          <Link
            onClick={() => {
              setNav(false);
            }}
            className=" text-white bg-[#1d5cd0] pt-[10px] pb-[10px] pl-[30px] pr-[30px] ml-[22px] rounded-[12px] mt-[12px] relative top-6 right-3"
            to="contact"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            Contact
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
