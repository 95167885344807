import React, { useState } from "react";
import Photo1 from "../assets/Photo1.png";
import Photo from "../assets/Photo.png";
import "./contact.css";
import { data1 } from "./Data";
const Help = () => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<div className="md:flex justify-center items-center block w-[80%] mx-auto pt-[55px] md:pt-[15px] md:pb-5 md:pl-7 md:mt-16 gap-10">
				<div className="md:w-[50%]">
					<h1 className="text-[#1F6CDD] text-[32px] font-bold">Mission</h1>
					<p className="">
						At AskMed, our mission is to revolutionize healthcare by leveraging
						the power of technology to make it accessible, personalized,
						empowering and secure. We are driven by a deep commitment to
						improving the health and well-being of individuals worldwide, and we
						invite you to join us on this transformative journey towards a
						healthier future.
					</p>
					<img src={Photo1} alt="" className="pt-9" />
				</div>
				<div className="md:w-[50%]">
					<h1 className="text-[#1F6CDD] text-[32px] font-bold">Vision</h1>
					<p className="">
						Our vision at AskMed is to empower individuals to lead healthier
						lives by seamlessly integrating technology and healthcare. We
						envision a future where everyone has instant access to personalized
						medical care, enhanced by the convenience of a comprehensive mobile
						application.
					</p>
					<img src={Photo} alt="" className="pt-9" />
				</div>
			</div>
			<div className="text-center w-[80%] mx-auto pt-16">
				<h1 className="text-[#1F6CDD] text-[35px] font-semibold">Goals</h1>
				<p>{open ? data1[0].info : `${data1[0].info.substring(0, 400)}...`}</p>
				<button
					onClick={() => setOpen(!open)} // Toggle the 'open' state when the button is clicked
					className="text-white bg-[#1d5cd0] pt-[10px] pb-[10px] pl-[30px] pr-[30px] rounded-[12px] mt-12"
				>
					{open ? "See Less" : "More"}
				</button>
			</div>
		</>
	);
};

export default Help;
