import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import "./contact.css";
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_av7084q",
        "template_89afunz",
        form.current,
        "JGjLQHEoLClVrvN1I"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div
      className="flex items-center justify-center flex-col cursor-pointer mt-16"
      id="contact"
    >
      <h1 className="text-center text-[35px] font-semibold text-[#1F6CDD]">
        Get in touch
      </h1>
      <h2 className="text-center text-[25px] font-normal text-[#1F6CDD]">
        Contact us
      </h2>

      <div className="container md:w-[50%] w-[80%] contact__container">
        <div className="contact__options"></div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="user_name"
            placeholder="Enter your full name"
            required
          />
          <input
            type="email"
            name="user_email"
            placeholder="Enter email"
            required
          />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button className="btn w-fit" type="submit" value="send">
            Submit Now
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
