import React from "react";
import Help from "./components/Help";
import Hero from "./components/Hero";
import History from "./components/History";
import Navbar from "./components/Navbar";
import Product1 from "./components/Product1";
import Product from "./components/Product";
import Testimonial from "./components/Testimonial";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
	return (
		<div>
			<Navbar />
			<Hero />
			<Product1
				title="About Us"
				title1="More About"
				description="AskMed is a comprehensive mobile application that seamlessly integrates the storage of medical data, 24/7 access to qualified doctors, medication reminders, and real-time updates on health activities. Having our AskMed Mobile Application is literally having a medical assistant in your pocket.
"
				button="More About"
			/>
			<History />
			<Help />
			<Testimonial />
			<Product />
			<Contact />
			<Footer />
		</div>
	);
}

export default App;
