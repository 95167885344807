import React from "react";
import "./contact.css";
const Testimonial = () => {
	return (
		<div id="team" className="team z-10">
			<h1 className="text-center text-[35px] font-bold text-[#1F6CDD] pt-12">
				Our Team
			</h1>
			<div className="flex flex-col md:flex-row justify-center items-center flex-wrap mx-auto w-[80%] gap-20 mt-20 relative">
				<div
					className="w-[100%] h-[233px] md:w-[40%] 
      bg-[#f3f0f0] flex items-start justify-center flex-col pl-3"
				>
					<h1 className="text-[#1F6CDD] md:text-[22px] text-[17px] font-normal">
						Director
					</h1>
					<p className="md:text-[15px] text-[11px]">
						• Dr. Mondel George MD, MEd, MBA, BSc.
					</p>
				</div>
				<div
					className="w-[100%] h-[233px] md:w-[40%]
      bg-[#f3f0f0] flex items-start justify-center flex-col pl-3"
				>
					<h1 className=" text-[#1F6CDD] text-[22px] font-normal">
						Marketing and Distribution
					</h1>
					<p className="text-[15px] p-1">• Anika Davis BSc.</p>
				</div>
				<div
					className="w-[100%] h-[233px] md:w-[40%]
      bg-[#f3f0f0] flex items-start justify-center flex-col pl-3"
				>
					<h1 className="text-[#1F6CDD] text-[22px] font-normal">Physician</h1>
					<p className="text-[15px]"> Dr. Mondel George</p>
					<p className="text-[15px]"> Dr. Christopher Gilbert</p>
					<p className="text-[15px]">•3 Additional physicians</p>
				</div>
				<div
					className="w-[100%] h-[233px] md:w-[40%]
      bg-[#f3f0f0] flex items-start justify-center flex-col pl-3"
				>
					<h1 className="text-[#1F6CDD] text-[22px] font-normal">Nurses</h1>
					<p className="text-[15px]">Supervisior: Ronasha William</p>
					<p className="text-[15px]">•3 Experienced Registered Nurses</p>
				</div>
				<div className="h-full w-full bg-transparent z-10 absolute flex items-center justify-center">
					<button className="bg-[#1F6CDD] p-16 rounded-[50%] text-white text-[25px] font-bold hidden md:block team1">
						Team
					</button>
				</div>
			</div>
		</div>
	);
};

export default Testimonial;
