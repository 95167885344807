import React from "react";
import hero from "../assets/hero.png";
import "./contact.css";
import button from "../assets/Button.png";
import button1 from "../assets/Button1.png";
const Hero = () => {
  return (
    <div
      className=" md:flex justify-around items-center w-[80%] mx-auto block pt-32"
      id="home"
    >
      <div className="">
        <h3 className=" text-black text-[20px] font-bold">Welcome to</h3>
        <h1 className="text-[58px] font-sans font-bold text-[#FC3030]">
          Ask<span className=" text-[#1F6CDD]">med</span>
        </h1>
        <p className="">
          Offering a holistic approach to healthcare management that is
          convenient, personalized, and reliable.
        </p>
        <h1 className="pt-5">DOWNLOAD APPLICATION FROM HERE</h1>
        <div className="flex gap-3 md:w-[400px] w-[300px] pt-6">
          <a href="https://apps.apple.com/pk/app/askmed/id1638140406">
            <img src={button} alt="" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.askmed.askmed">
            <img src={button1} alt="" />
          </a>
        </div>
      </div>
      <img src={hero} alt="" className=" md:w-[50%] mx-auto" />
    </div>
  );
};

export default Hero;
