import React from "react";
import image1 from "../assets/image1.png";
const Product1 = (props) => {
  return (
    <>
      <div
        className="md:flex justify-around items-center block w-[80%] mx-auto"
        id="about"
      >
        <div className="md:w-[50%]">
          <h1 className="text-[#1F6CDD] pt-9 text-[35px] font-semibold ">
            {props.title}
          </h1>
          <p className=" text-[19px] max-w-[500px] pt-4">{props.description}</p>
        </div>
        <div className="md:w-[50%]">
          <img src={image1} alt="" className=" md:w-full md:object-contain" />
        </div>
      </div>
    </>
  );
};

export default Product1;
