import React, { useState } from "react";
import { data } from "./Data";

const History = () => {
	// Use array destructuring to get the state value (read) and state setter function (setRead)
	const [read, setRead] = useState(false);

	return (
		<div className="text-center w-[80%] mx-auto pt-16">
			<h1 className="text-[#1F6CDD] text-[35px] font-semibold">History</h1>
			<p>{read ? data[0].info : `${data[0].info.substring(0, 400)}...`}</p>
			<button
				className="text-white bg-[#1d5cd0] pt-[10px] pb-[10px] pl-[30px] pr-[30px] rounded-[12px] mt-12"
				onClick={() => setRead(!read)} // Toggle the read state when the button is clicked
			>
				{read ? "See Less" : " More"}
			</button>
		</div>
	);
};

export default History;
