import React from "react";
import "./contact.css";
import logo from "../assets/logo.png";
import { Link } from "react-scroll";
const Footer = () => {
	return (
		<div className="pt-32">
			<footer>
				<div className="content">
					<h1 className="text-[34px] font-bold text-[#FC3030] flex items-center">
						<span>
							<img src={logo} alt="" />
						</span>
						Ask<span className="text-[#1F6CDD]">med</span>
					</h1>
					<ul className="socials">
						<li>
							<Link
								className=" cursor-pointer"
								to="home"
								spy={true}
								smooth={true}
								offset={50}
								duration={500}
							>
								Home
							</Link>
						</li>
						<li>
							<Link
								className=" cursor-pointer"
								to="about"
								spy={true}
								smooth={true}
								offset={50}
								duration={500}
							>
								About
							</Link>
						</li>
						<li>
							<Link
								className=" cursor-pointer"
								to="team"
								spy={true}
								smooth={true}
								offset={50}
								duration={500}
							>
								Team
							</Link>
						</li>
						<li>
							<Link
								className=" cursor-pointer"
								to="faqs"
								spy={true}
								smooth={true}
								offset={50}
								duration={500}
							>
								FAQs
							</Link>
						</li>
						<li>
							<Link
								className=" cursor-pointer"
								to="contact"
								spy={true}
								smooth={true}
								offset={50}
								duration={500}
							>
								Contact
							</Link>
						</li>
					</ul>
				</div>
				<div className="footer-bottom">
					<p>© 2023 All Rights Reserved , Inc.</p>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
